import React, {
	DragEventHandler,
	MouseEventHandler,
	ReactElement,
	useCallback,
	useEffect,
	useRef,
	useState,
} from 'react'
import { ArrowDownRight, Loader, Maximize2, MoreHorizontal, Move } from 'react-feather'
import styled, { keyframes } from 'styled-components'
import { OverflowActionMenu } from '../menus/OverflowAction'
import Modal from '../modals/Modal'
import useModal from '../modals/useModal'
import { TextLink } from '../Text'
import { useOnClickOutside } from '../utils/useOnClickOutside'
import { View, ViewProps } from '../View'

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

interface DashboardItemPlaceholderWrapperProps extends ViewProps {
	draggedOver?: boolean
}

const DashboardItemPlaceholderWrapper = styled(View).attrs({ as: styled.div`` })<DashboardItemPlaceholderWrapperProps>(
	{
		backgroundColor: 'rgba(50, 50, 50, 0.04)',
		borderRadius: '10px',
		cursor: 'crosshair',
	},
	`
	&.selected {
		background-color: rgba(50, 50, 50, 0.4);
	}
	`,
	({ draggedOver }) => (draggedOver ? {
		opacity: 0.4,
	} : {}),
)

interface DashboardItemPlaceholderProps extends DashboardItemPlaceholderWrapperProps {
	onChartDrop?: (chartId: string, x: number, y: number) => void
	x: number
	y: number
}

function DashboardItemPlaceholder({ onChartDrop, x, y, ...props }: DashboardItemPlaceholderProps) {
	const [ draggedOver, setDraggedOver ] = useState<boolean>(false)

	const onDragLeave = useCallback(() => {
		setDraggedOver(false)
	}, [])

	const onDragOver = useCallback<DragEventHandler<HTMLSpanElement>>(e => {
		setDraggedOver(true)
		e.preventDefault()
	}, [])

	const onDrop = useCallback<DragEventHandler<HTMLSpanElement>>(e => {
		setDraggedOver(false)
		onChartDrop && onChartDrop(e.dataTransfer.getData('chart_id'), x, y)
		e.preventDefault()
	}, [ onChartDrop, x, y ])

	return (
		<DashboardItemPlaceholderWrapper
			{...props}
			gridColumnStart={x}
			gridRowStart={y}
			draggedOver={draggedOver}
			onDragLeave={onDragLeave}
			onDragOver={onDragOver}
			onDrop={onDrop}
		/>
	)
}

const DashboardWrapper = styled(View).attrs({ as: styled.div`` })<ViewProps>(
	{
		display: 'grid',
		userSelect: 'none',
		gridAutoRows: '1fr',
		borderRadius: '20px',
		margin: '0 10px 10px 10px',
		'&': {
			'@media (width <= 1000px)': {
				display: 'flex',
				flexDirection: 'column',
				height: 'fit-content',
				'.dashboard-item:has(canvas), .dashboard-item:has(table)': {
					aspectRatio: '1',
					maxHeight: 'calc(min(80vw, 100vh - 150px))',
				},
				'.empty-dashboard-item': {
					display: 'none',
				},
			},
		},
	},
	({ theme }) => ({ color: theme.dashboard.backgroundColor }),
)

export interface DashboardProps extends ViewProps {
	columns: number
	emptyCells?: number
	rows?: number
	onChartDrop?: DashboardItemPlaceholderProps['onChartDrop']
	onCreateChart?: (chart: object) => void
	children: any
}

export function Dashboard({ children, columns, rows, emptyCells, onChartDrop, onCreateChart, ...props }: DashboardProps) {
	const [ mouseDown, setMouseDown ] = useState(false)
	const [ error, setError ] = useState(false)
	const [ size, setSize ] = useState({ x: 0, y: 0, width: 0, height: 0 })
	const [ clickedPosition, setClickedPosition ] = useState({ x: 0, y: 0 })
	const [ currentPosition, setCurrentPosition] = useState({ x: 0, y: 0 })
	const dashboardRef = useRef(null)

	const onMouseDown = useCallback<NonNullable<ViewProps['onMouseDown']>>(e => {
		setClickedPosition({ x: e.clientX, y: e.clientY})
		setCurrentPosition({ x: e.clientX, y: e.clientY})
		setMouseDown(true)
	}, [])

	const onMouseMove = useCallback<MouseEventHandler<HTMLSpanElement>>(e => {
		if (mouseDown) {
			setCurrentPosition({ x: e.clientX, y: e.clientY})
		}
	}, [ mouseDown ])

	useEffect(() => {
		const marker = document.getElementById('marker')
		const gridItems = document.getElementsByClassName('empty-dashboard-item')
		const dashboardItems = document.getElementsByClassName('dashboard-item')
		let chartX = [100000, 0]
		let chartY = [100000, 0]
		if (!dashboardRef.current) {
			return
		}
		if (mouseDown && marker) {
			const markerRect = marker.getBoundingClientRect()
			Array.from(gridItems).forEach(item => {
				const itemRect = item.getBoundingClientRect()
				if (itemRect.x <= markerRect.x + markerRect.width && itemRect.x + itemRect.width >= markerRect.x) {
					if (itemRect.y <= markerRect.y + markerRect.height && itemRect.y + itemRect.height >= markerRect.y) {
						item.classList.add('selected')
						chartX = [
							Math.min(parseInt(window.getComputedStyle(item).gridColumnStart || ''), chartX[0]),
							Math.max(parseInt(window.getComputedStyle(item).gridColumnStart || ''), chartX[1]),
						]
						chartY = [
							Math.min(parseInt(window.getComputedStyle(item).gridRowStart || ''), chartY[0]),
							Math.max(parseInt(window.getComputedStyle(item).gridRowStart || ''), chartY[1]),
						]
						return
					}
				}
				item.classList.remove('selected')
			})
			const markedDashboardItems = Array.from(dashboardItems).filter(item => {
				const itemRect = item.getBoundingClientRect()
				if (itemRect.x <= markerRect.x + markerRect.width && itemRect.x + itemRect.width >= markerRect.x) {
					if (itemRect.y <= markerRect.y + markerRect.height && itemRect.y + itemRect.height >= markerRect.y) {
						return true
					}
				}
				return false
			})
			if (markedDashboardItems.length) {
				setError(true)
			} else {
				setError(false)
				setSize({ x: chartX[0], y: chartY[0], width: chartX[1] - chartX[0] + 1, height: chartY[1] - chartY[0] + 1 })
			}
		}
		if (!mouseDown) {
			Array.from(gridItems).forEach(item => {
				item.classList.remove('selected')
			})
		}
	}, [ currentPosition, mouseDown ])

	return (
		<DashboardWrapper
			ref={dashboardRef}
			gridTemplateColumns={columns && `repeat(${columns}, 1fr)`}
			gridTemplateRows={rows && `repeat(${rows}, 1fr)`}
			{...props}
			cursor={mouseDown ? 'crosshair' : undefined}
			onMouseMove={onMouseMove}
			onMouseUp={() => {
				if (!error && mouseDown) {
					onCreateChart && onCreateChart(size)
				}
				setMouseDown(false)
			}}
			id="dashboard"
		>
				{children}
			{[...Array(columns * (rows ?? 1))].map((_, index) => (
				<DashboardItemPlaceholder
					key={index}
					x={index % columns + 1}
					y={parseInt(String(index / columns)) + 1}
					onMouseDown={onMouseDown}
					onChartDrop={onChartDrop}
					draggable={false}
					className="empty-dashboard-item"
				/>
			))}
			{mouseDown &&
				<View
					position="absolute"
					style={{
						left: Math.min(clickedPosition.x, currentPosition.x),
						top: Math.min(clickedPosition.y, currentPosition.y),
						width: Math.abs(currentPosition.x - clickedPosition.x),
						height: Math.abs(currentPosition.y - clickedPosition.y),
						transition: 'background-color 0.15s',
					}}
					bg={error ? 'rgba(200, 0, 0, 0.2)' : 'rgba(50, 50, 50, 0.1)'}
					id="marker"
				/>
			}
		</DashboardWrapper>
	)
}

export const DashboardItemWrapper = styled(View).attrs({ as: styled.div`` })<ViewProps>(
	{
		width: '100%',
		position: 'relative',
		borderRadius: '10px',
		border: '1px solid',
	},
	({ draggable }) => (draggable ? {
		cursor: 'move',
	} : {}),
	({ theme }) => ({ backdropFilter: theme.dashboardItem.backdropFilter }),
	({ theme }) => ({ backgroundColor: theme.dashboardItem.backgroundColor }),
	({ theme }) => ({ borderColor: theme.dashboardItem.borderColor }),
	({ theme }) => ({ color: theme.dashboardItem.color }),
)

const DashboardItemMenuWrapper = styled(View).attrs({ as: styled.div`` })<ViewProps>(
	{
		opacity: 0,
		zIndex: 200,
	},
	`
	${DashboardItemWrapper}:hover &, &.open {
		opacity: 1;
	}
	`,
)

export const Spinner = styled(View).attrs({ as: styled.div`` })<ViewProps>`
	animation: ${rotate} 2s linear infinite;
`

export function FullPageCenteredContent({ children }) {
	return (
		<View
			alignItems="center"
			inset="50%"
			justifyContent="center"
			position="absolute"
		>
			{children}
		</View>
	)
}

export function FullPageCenteredSpinner() {
	return (
		<FullPageCenteredContent>
			<Spinner as={Text} color="text">
				<View as={Loader} size={88}/>
			</Spinner>
		</FullPageCenteredContent>
	)
}

interface DashboardItemMenuProps extends ViewProps {
	onEditClick?: () => void
	onDownloadImageClick?: (ref: HTMLCanvasElement) => void
	onDownloadPdfClick?: (ref: HTMLCanvasElement) => void
	onDeleteChartClick?: () => void
}

function DashboardItemMenu({
	onEditClick,
	onDeleteChartClick,
	onDownloadImageClick,
	onDownloadPdfClick,
	...props
}: DashboardItemMenuProps) {
	const [ open, setOpen ] = useState<boolean>(false)
	const ref = useOnClickOutside<HTMLElement>(() => {
		setOpen(false)
	})

	const canvas = ref.current
		?.closest('.dashboard-item')
		?.querySelector('canvas') as HTMLCanvasElement

	const onDownloadImageClickWrapper = useCallback(() => {
		canvas && onDownloadImageClick?.(canvas)
	}, [ canvas, onDownloadImageClick ])

	const onDownloadPdfClickWrapper = useCallback(() => {
		canvas && onDownloadPdfClick?.(canvas)
	}, [ canvas, onDownloadPdfClick ])

	return (
		<DashboardItemMenuWrapper {...props} ref={ref} flexDirection="column" className={open ? 'open' : undefined}>
			<OverflowActionMenu>
				<TextLink to="" onClick={onEditClick} color="text">Edit chart</TextLink>
				{canvas && onDownloadPdfClick &&
				<TextLink to="" onClick={onDownloadPdfClickWrapper} color="text">Download as PDF</TextLink>
				}
				{canvas && onDownloadImageClick &&
				<TextLink to="" onClick={onDownloadImageClickWrapper} color="text">Download as image</TextLink>
				}
				<TextLink to="" color="text">Export to Excel</TextLink>
				<TextLink to="" color="text">Compare ...</TextLink>
				<TextLink to="" onClick={onDeleteChartClick} color="danger">Delete chart</TextLink>
			</OverflowActionMenu>
		</DashboardItemMenuWrapper>
	)
}

export interface DashboardItemProps extends ViewProps {
	x?: number
	y?: number
	width?: number
	height?: number
	isLoading?: boolean
	onEditClick?: DashboardItemMenuProps['onEditClick']
	onDeleteChartClick?: DashboardItemMenuProps['onDeleteChartClick']
	onDownloadImageClick?: DashboardItemMenuProps['onDownloadImageClick']
	onDownloadPdfClick?: DashboardItemMenuProps['onDownloadPdfClick']
	children?: ReactElement | string
	chartId?: string
}

export function DashboardItem({
	children,
	chartId,
	x,
	y,
	width,
	height,
	isLoading,
	onEditClick,
	onDeleteChartClick,
	onDownloadImageClick,
	onDownloadPdfClick,
	...props
}: DashboardItemProps) {
	const [ dragging, setDragging ] = useState<boolean>(false)
	const { show } = useModal()

	const onFullscreenClick = useCallback(() => {
		show((modalProps) => (
			<Modal {...modalProps}>
				<View overflow="auto" height="80vh" width="80vw">
					{children}
				</View>
			</Modal>
		))()
	}, [ children, show ])

	const onDragStart = useCallback<DragEventHandler<HTMLSpanElement>>(e => {
		setDragging(true)
		e.dataTransfer.setData('x', String(x))
		e.dataTransfer.setData('y', String(y))
		e.dataTransfer.setData('width', String(width))
		e.dataTransfer.setData('height', String(height))
		e.dataTransfer.setData('chart_id', String(chartId))
		e.currentTarget.style.display = 'none'
	}, [ setDragging ])

	const onDragEnd = useCallback<DragEventHandler<HTMLSpanElement>>(e => {
		setDragging(false)
		e.currentTarget.style.display = 'initial'
	}, [ setDragging ])

	return (
			<DashboardItemWrapper
				onDragStart={onDragStart}
				onDragEnd={onDragEnd}
				gridColumnStart={x}
				gridColumnEnd={width && `span ${width}`}
				gridRowStart={y}
				gridRowEnd={height && `span ${height}`}
				elevation={1}
				{...props}
				draggable={dragging}
				className="dashboard-item"
			>
				{props.draggable &&
				<DashboardItemMenuWrapper position="absolute" left="10px" top="10px">
					<View onMouseDown={() => setDragging(true)}>
						<Move size={20}/>
					</View>
				</DashboardItemMenuWrapper>
				}
				<DashboardItemMenuWrapper position="absolute" bottom="10px" right="10px">
					<View>
						<ArrowDownRight size={20}/>
					</View>
				</DashboardItemMenuWrapper>
				{isLoading &&
				<Spinner position="absolute" right={10} top="10px" zIndex={200}>
					<Loader size={20}/>
				</Spinner>
				}
				<DashboardItemMenu
					position="absolute"
					right={isLoading ? 40 : 10}
					top="10px"
					onEditClick={onEditClick}
					onDeleteChartClick={onDeleteChartClick}
					onDownloadImageClick={onDownloadImageClick}
					onDownloadPdfClick={onDownloadPdfClick}
				/>
				<DashboardItemMenuWrapper position="absolute" right={isLoading ? 70 : 40} top="10px">
					<View onClick={onFullscreenClick}>
						<Maximize2 size={20}/>
					</View>
				</DashboardItemMenuWrapper>
				<View
					overflow="auto"
					width="100%"
					pointerEvents={isLoading ? 'none' : undefined}
				>
					{children}
				</View>
			</DashboardItemWrapper>
	)
}
